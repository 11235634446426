import { addDictionary } from "@cospex/client/helpers/dictionary";

const dictionary = {
  en: {
    "page-not-found":
      "We haven't been able to track down your page! Sorry about that.",
  },
  fr: {
    "page-not-found":
      "Nous n'avons pas réussi à retrouver votre page ! Nous en sommes désolés.",
  },
  it: {
    "page-not-found":
      "Non siamo riusciti a rintracciare la tua pagina! Mi dispiace per questo.",
  },
  es: {
    "page-not-found":
      "¡No hemos podido localizar tu página! Lo siento por eso.",
  },
  de: {
    "page-not-found":
      "Wir konnten Ihre Seite nicht finden! Entschuldigung dafür.",
  },
};
const hook = addDictionary(dictionary, "parcel/components/PageNotFound");
export default hook;
