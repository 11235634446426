import { Box, BoxProps, Container, SxProps, Theme } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

const Section = forwardRef(
  (
    {
      children,
      fullWidth,
      sx,
      ...props
    }: {
      children: React.ReactNode;
      fullWidth?: boolean;
      sx?: SxProps<Theme>;
    } & BoxProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Box
        className="Section"
        component="section"
        ref={ref}
        sx={{
          py: {
            xs: 4,
            md: 12,
          },
          overflow: "hidden",
          ...sx,
        }}
        {...props}
      >
        <Container maxWidth={fullWidth ? false : "lg"}>{children}</Container>
      </Box>
    );
  }
);

Section.displayName = "Section";

export default Section;
