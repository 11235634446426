import { addDictionary } from "@cospex/client/helpers/dictionary";

const dictionary = {
  en: {
    "footer-description":
      "{{name}} provides a online platform to track your packages from all major delivery companies. Never lose your parcels again!",
    "footer-link-login": "Login",
    "footer-link-unsubscribe": "Unsubscribe",
    "footer-link-prices": "Prices",
    "footer-link-terms": "Terms and conditions",
    "footer-link-refunds": "Refund policy",
    "footer-link-privacy": "Privacy policy",
    "footer-link-cookies": "Cookie policy",
    "footer-link-contact": "Contact us",
  },
  fr: {
    "footer-description":
      "{{name}} fournit une plate-forme en ligne pour suivre vos colis de toutes les principales sociétés de livraison. Ne perdez plus jamais vos colis !",
    "footer-link-login": "Connexion",
    "footer-link-unsubscribe": "Se désabonner",
    "footer-link-prices": "Tarifs",
    "footer-link-terms": "Termes et conditions",
    "footer-link-refunds": "Politique de remboursement",
    "footer-link-privacy": "Politique de confidentialité",
    "footer-link-cookies": "Politique relative aux cookies",
    "footer-link-contact": "Contactez-nous",
  },
  it: {
    "footer-description":
      "{{name}} fornisce una piattaforma online per tracciare i tuoi pacchi da tutte le principali società di consegna. Non perdere mai più i tuoi pacchi!",
    "footer-link-login": "Accesso",
    "footer-link-unsubscribe": "Annulla l'iscrizione",
    "footer-link-prices": "Prezzi",
    "footer-link-terms": "Termini e Condizioni",
    "footer-link-refunds": "Politica di rimborso",
    "footer-link-privacy": "Politica sulla privacy",
    "footer-link-cookies": "Politica dei cookie",
    "footer-link-contact": "Contattaci",
  },
  es: {
    "footer-description":
      "{{name}} proporciona una plataforma en línea para rastrear sus paquetes de todas las principales empresas de entrega. ¡Nunca pierdas tus paquetes de nuevo!",
    "footer-link-login": "Iniciar sesión",
    "footer-link-unsubscribe": "Darse de baja",
    "footer-link-prices": "Precios",
    "footer-link-terms": "Términos y condiciones",
    "footer-link-refunds": "Política de reembolso",
    "footer-link-privacy": "Política de privacidad",
    "footer-link-cookies": "Política de cookies",
    "footer-link-contact": "Contáctenos",
  },
  de: {
    "footer-description":
      "{{name}} bietet eine Online-Plattform, um Ihre Pakete von allen großen Lieferunternehmen zu verfolgen. Verlieren Sie nie wieder Ihre Pakete!",
    "footer-link-login": "Anmelden",
    "footer-link-unsubscribe": "Abmelden",
    "footer-link-prices": "Preise",
    "footer-link-terms": "Geschäftsbedingungen",
    "footer-link-refunds": "Rückerstattungsrichtlinie",
    "footer-link-privacy": "Datenschutzrichtlinie",
    "footer-link-cookies": "Cookie-Richtlinie",
    "footer-link-contact": "Kontaktieren Sie uns",
  },
};
const hook = addDictionary(dictionary, "parcel/components/Footer");
export default hook;
