import CompanyInfo from "@cospex/client/components/CompanyInfo";
import LanguageSelect from "@cospex/client/components/LanguageSelect";
import MarketingEmail from "@cospex/client/components/MarketingEmail";
import Section from "@cospex/client/components/Section";
import { useLanguage } from "@cospex/client/helpers/dictionary";
import { useGlobalConfig } from "@cospex/client/hooks/useGlobalConfig";
import Logo from "@cospex/client/parcel/components/Logo";
import PaymentProcessorImg from "@cospex/client/parcel/img/payment-processor.svg";
import { Box, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import useTranslation from "./dictionary";

export default function Footer() {
  const lang = useLanguage();
  const { t } = useTranslation();
  const { languages } = useGlobalConfig();
  return (
    <footer>
      <Section
        sx={{
          pt: 4,

          ul: {
            mt: 0,
            paddingLeft: 0,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            listStyle: "none",
          },
        }}
      >
        <Grid container spacing={4}>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 4,
            }}
          >
            <Logo />
            <Typography variant="body2" sx={{ maxWidth: 300, pb: "1rem" }}>
              {t("footer-description", { name: __APP_NAME__ })}
            </Typography>
            <LanguageSelect options={languages} />
            <Box sx={{ mt: "2rem" }}>
              <Box
                component="img"
                sx={{
                  maxWidth: 180,
                  ...(lang === "fr"
                    ? {}
                    : {
                        clipPath: "inset(0 calc(100% - 140px) 0 0)",
                        transform: "translateX(+20px)",
                      }),
                }}
                src={PaymentProcessorImg}
              />
            </Box>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 2,
            }}
          >
            <ul>
              <li>
                <Link target="_blank" href="/login">
                  {t("footer-link-login")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/unsubscribe">
                  {t("footer-link-unsubscribe")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/prices">
                  {t("footer-link-prices")}
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 2,
            }}
          >
            <ul>
              <li>
                <Link target="_blank" href="/terms">
                  {t("footer-link-terms")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/refunds">
                  {t("footer-link-refunds")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/privacy">
                  {t("footer-link-privacy")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/cookies">
                  {t("footer-link-cookies")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/contact">
                  {t("footer-link-contact")}
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 4,
            }}
          >
            <MarketingEmail />
          </Grid>
        </Grid>
      </Section>
      <Section
        sx={{
          py: 2,
          borderTop: "1px solid",
          borderColor: "grey.200",
        }}
      >
        <Typography variant="body2" color="text.secondary" mb={1.5}>
          Copyright {" © "}
          {new Date().getFullYear()}
          <Link color="inherit" href="https://material-ui.com/">
            {" "}
            {__APP_NAME__}
          </Link>
        </Typography>
        <CompanyInfo color="#586174" textAlign="left" />
      </Section>
    </footer>
  );
}
