import React, { createContext } from "react";

export interface IParcelConfig {
  carrierLogosImg: string;
  mainGraphicLogosImg?: string;
  mainGraphicLogosImgMobile?: string;
  mainGraphicMessageOffset?: string;
  /** @default true */
  hasMainGraphicMessage?: boolean;
}

export const ParcelConfigContext = createContext<IParcelConfig | undefined>(
  undefined
);

export default function ParcelConfigProvider({
  children,
  value,
}: {
  value: IParcelConfig;
  children: React.ReactNode;
}) {
  if (typeof value.hasMainGraphicMessage === "undefined") {
    value.hasMainGraphicMessage = true;
  }
  return (
    <ParcelConfigContext.Provider value={value}>
      {children}
    </ParcelConfigContext.Provider>
  );
}
