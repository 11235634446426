import FrontAppChat from "@cospex/client/components/FrontAppChat";
import App from "@cospex/client/parcel/components/App";

export default function Root() {
  return (
    <>
      <FrontAppChat chatId="e084b8bd5c67fca8fdf4af54a17687bc" />
      <App />
    </>
  );
}
